import React from "react";
import {
  Navigate,
  Outlet,
  // useLocation
} from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  // REDIRECT_URL_KEY,
} from "configs/AppConfig";
import Utils from "utils";

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  // const location = useLocation();

  if (!token) {
    const companyId = Utils.getCompanyId();
    return (
      <Navigate
        // to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        to={`${
          companyId ? `/${companyId}` : ""
        }${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}`}
        replace
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
