import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: `${process.env.REACT_APP_API_URL}/signin`,
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "application/json",
      "accept-language": navigator.language,
    },
    data: data,
  });
};

AuthService.logout = function () {
  return fetch({
    url: `${process.env.REACT_APP_API_URL}/signout`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "accept-language": navigator.language,
    },
  });
};

AuthService.register = function (data) {
  return fetch({
    url: `${process.env.REACT_APP_API_URL}/auth/signup`,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export default AuthService;
