import React from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";
// import { AUTHENTICATED_ENTRY } from "configs/AppConfig";
import { protectedRoutes, publicRoutes } from "configs/RoutesConfig";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "store/slices/authSlice";
import { useNavigate } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppRoute from "./AppRoute";
import Utils from "utils";
import ErrorBoundary from "ErrorBoundary";
import ErrorOne from "views/auth-views/errors/error-page-1";

const Routes = () => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (token) {
      const path = location.pathname.split("/");
      switch (path.length) {
        case 2:
          if (path[1] !== "error") {
            dispatch(signOut());
            // navigate("/auth/login");
            navigate(`/${localStorage.getItem("companyId")}/auth/login`);
          }
          break;
        case 3:
          if (path[2] !== "projects") {
            navigate(`/${path[1]}/${path[2]}/dashboards/default`);
          }
          break;
        default:
          break;
      }
    }
  }, [location]);

  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute />}>
        {/* <Route
          path="/" */}
        {/* element={<Navigate replace to={AUTHENTICATED_ENTRY} />} */}
        {/* element={
            <Navigate
              replace
              to={`/${Utils.getCompanyId()}/${Utils.getProjectId()}/dashboards/default`}
            />
          }
        /> */}
        {protectedRoutes.map((route, index) => {
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                // <ErrorBoundary>
                //   <AppRoute
                //     routeKey={route.key}
                //     component={route.component}
                //     {...route.meta}
                //   />
                // </ErrorBoundary>
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
        <Route path="/error" element={<ErrorOne />} />
        <Route path="*" element={<Navigate to="/error" replace />} />
      </Route>
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
