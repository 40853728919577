import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import IntlMessage from "components/util-components/IntlMessage"; // usually used alongside timezone

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

export const categoryOptions = [
  { type: "Application", label: "Health/Fitness", value: "00" },
  { type: "Application", label: "Education", value: "01" },
  { type: "Application", label: "Finance", value: "02" },
  { type: "Application", label: "Weather", value: "03" },
  { type: "Application", label: "News/Magazine", value: "04" },
  { type: "Application", label: "Date", value: "05" },
  { type: "Application", label: "Tool", value: "06" },
  { type: "Application", label: "Book/Reference", value: "07" },
  { type: "Application", label: "Video player/Editor", value: "08" },
  { type: "Application", label: "Library/Demo", value: "09" },
  { type: "Application", label: "Lifestyle", value: "10" },
  { type: "Application", label: "Cartoon", value: "11" },
  { type: "Application", label: "Personalize", value: "12" },
  { type: "Application", label: "Real estate/Home interior", value: "13" },
  { type: "Application", label: "Beauty", value: "14" },
  { type: "Application", label: "Business", value: "15" },
  { type: "Application", label: "Photo", value: "16" },
  { type: "Application", label: "Productivity", value: "17" },
  { type: "Application", label: "Social", value: "18" },
  { type: "Application", label: "Shopping", value: "19" },
  { type: "Application", label: "Sports", value: "20" },
  { type: "Application", label: "Drink", value: "21" },
  { type: "Application", label: "Entertainment", value: "22" },
  { type: "Application", label: "Travel and local information", value: "23" },
  { type: "Application", label: "Art/Design", value: "24" },
  { type: "Application", label: "Music/Audio", value: "25" },
  { type: "Application", label: "Medical care", value: "26" },
  { type: "Application", label: "Event", value: "27" },
  { type: "Application", label: "Car", value: "28" },
  { type: "Application", label: "Map/Navigation", value: "29" },
  { type: "Application", label: "Parenting", value: "30" },
  { type: "Application", label: "Communication", value: "31" },
  { type: "Game", label: "Education", value: "100" },
  { type: "Game", label: "Vocabulary", value: "101" },
  { type: "Game", label: "Role playing", value: "102" },
  { type: "Game", label: "Board", value: "103" },
  { type: "Game", label: "Sports", value: "104" },
  { type: "Game", label: "Simulation", value: "105" },
  { type: "Game", label: "Arcade", value: "106" },
  { type: "Game", label: "Action", value: "107" },
  { type: "Game", label: "Adventure", value: "108" },
  { type: "Game", label: "Music", value: "109" },
  { type: "Game", label: "Car racing", value: "110" },
  { type: "Game", label: "Strategy", value: "111" },
  { type: "Game", label: "Card", value: "112" },
  { type: "Game", label: "Casino", value: "113" },
  { type: "Game", label: "Casual", value: "114" },
  { type: "Game", label: "Quiz", value: "115" },
  { type: "Game", label: "Puzzle", value: "116" },
];

export const languageOptions = [
  { label: <IntlMessage id="Arabic" />, value: "ar" },
  { label: <IntlMessage id="Bulgarian" />, value: "bg" },
  { label: <IntlMessage id="Catalan" />, value: "ca" },
  { label: <IntlMessage id="Chinese (simplified)" />, value: "zh-Hans" },
  { label: <IntlMessage id="Chinese (traditional)" />, value: "zh-Hant" },
  { label: <IntlMessage id="Croatian" />, value: "hr" },
  { label: <IntlMessage id="Czech" />, value: "cs" },
  { label: <IntlMessage id="Danish" />, value: "da" },
  { label: <IntlMessage id="Dutch" />, value: "nl" },
  { label: <IntlMessage id="English" />, value: "en" },
  { label: <IntlMessage id="Estonian" />, value: "et" },
  { label: <IntlMessage id="Filipino" />, value: "tl" },
  { label: <IntlMessage id="Finnish" />, value: "fi" },
  { label: <IntlMessage id="French" />, value: "fr" },
  { label: <IntlMessage id="German" />, value: "de" },
  { label: <IntlMessage id="Greek" />, value: "el" },
  { label: <IntlMessage id="Hebrew" />, value: "he" },
  { label: <IntlMessage id="Hindi" />, value: "hi" },
  { label: <IntlMessage id="Hungarian" />, value: "hu" },
  { label: <IntlMessage id="Icelandic" />, value: "is" },
  { label: <IntlMessage id="Indonesian" />, value: "id" },
  { label: <IntlMessage id="Italian" />, value: "it" },
  { label: <IntlMessage id="Japanese" />, value: "ja" },
  { label: <IntlMessage id="Korean" />, value: "ko" },
  { label: <IntlMessage id="Latvian" />, value: "lv" },
  { label: <IntlMessage id="Lithuanian" />, value: "lt" },
  { label: <IntlMessage id="Norwegian" />, value: "no" },
  { label: <IntlMessage id="Polish" />, value: "pl" },
  { label: <IntlMessage id="Portuguese" />, value: "pt" },
  { label: <IntlMessage id="Romanian" />, value: "ro" },
  { label: <IntlMessage id="Russian" />, value: "ru" },
  { label: <IntlMessage id="Serbian" />, value: "sr" },
  { label: <IntlMessage id="Slovak" />, value: "sk" },
  { label: <IntlMessage id="Slovenian" />, value: "sl" },
  { label: <IntlMessage id="Spanish" />, value: "es" },
  { label: <IntlMessage id="Swedish" />, value: "sv" },
  { label: <IntlMessage id="Thai" />, value: "th" },
  { label: <IntlMessage id="Turkish" />, value: "tr" },
  { label: <IntlMessage id="Ukrainian" />, value: "uk" },
  { label: <IntlMessage id="Urdu" />, value: "ur" },
  { label: <IntlMessage id="Vietnamese" />, value: "vi" },
  { label: <IntlMessage id="Malay" />, value: "ms" },
];

export const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 24,
  },
  size: "small",
};

export const loginFormItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
  size: "small",
};

export const wideFormItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
  size: "small",
};

export const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 6 },
    sm: { span: 24, offset: 6 },
  },
};

export const wideFormItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 8 },
    sm: { span: 24, offset: 8 },
  },
};

export const narrowFormItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 0 },
  },
};

let yesterday = dayjs().subtract(1, "day");
export const SHARED_YESTERDAY_DATE = yesterday.toISOString();

let twoDaysAgo = dayjs().subtract(2, "days");
export const SHARED_TWO_DAYS_AGO_DATE = twoDaysAgo.toISOString();

let monthAgo = dayjs().tz().subtract(1, "month");
export const SHARED_START_DATE = monthAgo.toISOString();

export const SHARED_END_DATE = dayjs().tz().toISOString();

export const presets = [
  {
    label: <IntlMessage id="Today" />,
    value: [dayjs(), dayjs().add(1, "day")],
  },
  {
    label: <IntlMessage id="Yesterday" />,
    value: [dayjs().subtract(1, "days"), dayjs()],
  },
  {
    label: <IntlMessage id="Past 7 Days" />,
    value: [dayjs().subtract(6, "days"), dayjs()],
  },
  {
    label: <IntlMessage id="Past 30 Days" />,
    value: [dayjs().subtract(29, "days"), dayjs()],
  },
  {
    label: <IntlMessage id="Past 60 Days" />,
    value: [dayjs().subtract(59, "days"), dayjs()],
  },
];

export const regionData = {
  1: {
    // KR
    url: "https://kr.object.ncloudstorage.com",
    domain: "kr.object.ncloudstorage.com",
    regionName: "kr-standard",
  },
  3: {
    // HK
    url: "https://sg.object.ncloudstorage.com",
    domain: "sg.object.ncloudstorage.com",
    regionName: "sg-standard",
  },
  7: {
    // SGN
    url: "https://sg.object.ncloudstorage.com",
    domain: "sg.object.ncloudstorage.com",
    regionName: "sg-standard",
  },
  8: {
    // JPN
    url: "https://jp.object.ncloudstorage.com",
    domain: "jp.object.ncloudstorage.com",
    regionName: "jp-standard",
  },
  9: {
    // USWN
    url: "https://us.object.ncloudstorage.com",
    domain: "us.object.ncloudstorage.com",
    regionName: "us-standard",
  },
  10: {
    // DEN
    url: "https://de.object.ncloudstorage.com",
    domain: "de.object.ncloudstorage.com",
    regionName: "de-standard",
  },
};

export const regionDataBeta = {
  1: {
    // KR
    url: "https://kr.beta-object.ncloudstorage.com",
    domain: "kr.beta-object.ncloudstorage.com",
    regionName: "kr-standard",
  },
  3: {
    // HK
    url: "http://sg.beta-object.ncloudstorage.com",
    domain: "sg.beta-object.ncloudstorage.com",
    regionName: "sg-standard",
  },
  7: {
    // SGN
    url: "http://sg.beta-object.ncloudstorage.com",
    domain: "sg.beta-object.ncloudstorage.com",
    regionName: "sg-standard",
  },
  // JPN / USWN / DEN 리전은 Beta 환경이 없음. 따라서 임의로 한국 Beta로 연결 됨.
  // '8': {
  //   // JPN
  //   url: 'https://jp.beta-object.ncloudstorage.com',
  //   domain: 'jp.beta-object.ncloudstorage.com',
  //   regionName: 'jp-standard',
  // },
  // '9': {
  //   // USWN
  //   url: 'https://us.beta-object.ncloudstorage.com',
  //   domain: 'us.beta-object.ncloudstorage.com',
  //   regionName: 'us-standard',
  // },
  // '10': {
  //   // DEN
  //   url: 'https://de.beta-object.ncloudstorage.com',
  //   domain: 'de.beta-object.ncloudstorage.com',
  //   regionName: 'de-standard',
  // },
};

export const timezoneCurrencyMap = [
  // North America
  { timezone: "America/St_Johns", currency: "CAD" },
  { timezone: "America/Halifax", currency: "CAD" },
  { timezone: "America/New_York", currency: "USD" },
  { timezone: "America/Chicago", currency: "USD" },
  { timezone: "America/Denver", currency: "USD" },
  { timezone: "America/Phoenix", currency: "USD" },
  { timezone: "America/Los_Angeles", currency: "USD" },
  { timezone: "America/Anchorage", currency: "USD" },
  { timezone: "America/Honolulu", currency: "USD" },

  // South America
  { timezone: "America/Sao_Paulo", currency: "BRL" },
  { timezone: "America/Argentina/Buenos_Aires", currency: "ARS" },
  { timezone: "America/Bogota", currency: "COP" },
  { timezone: "America/Caracas", currency: "VES" },
  { timezone: "America/Lima", currency: "PEN" },
  { timezone: "America/Santiago", currency: "CLP" },
  { timezone: "America/Mexico_City", currency: "MXN" },
  { timezone: "America/Panama", currency: "PAB" },
  { timezone: "America/Guayaquil", currency: "USD" },

  // Europe
  { timezone: "Europe/Lisbon", currency: "EUR" },
  { timezone: "Europe/London", currency: "GBP" },
  { timezone: "Europe/Madrid", currency: "EUR" },
  { timezone: "Europe/Paris", currency: "EUR" },
  { timezone: "Europe/Berlin", currency: "EUR" },
  { timezone: "Europe/Rome", currency: "EUR" },
  { timezone: "Europe/Athens", currency: "EUR" },
  { timezone: "Europe/Moscow", currency: "RUB" },
  { timezone: "Europe/Istanbul", currency: "TRY" },
  { timezone: "Europe/Amsterdam", currency: "EUR" },
  { timezone: "Europe/Oslo", currency: "NOK" },
  { timezone: "Europe/Stockholm", currency: "SEK" },
  { timezone: "Europe/Helsinki", currency: "EUR" },
  { timezone: "Europe/Bucharest", currency: "RON" },
  { timezone: "Europe/Prague", currency: "CZK" },
  { timezone: "Europe/Budapest", currency: "HUF" },
  { timezone: "Europe/Warsaw", currency: "PLN" },
  { timezone: "Europe/Kiev", currency: "UAH" },

  // Asia
  { timezone: "Asia/Jerusalem", currency: "ILS" },
  { timezone: "Asia/Riyadh", currency: "SAR" },
  { timezone: "Asia/Dubai", currency: "AED" },
  { timezone: "Asia/Tehran", currency: "IRR" },
  { timezone: "Asia/Kabul", currency: "AFN" },
  { timezone: "Asia/Karachi", currency: "PKR" },
  { timezone: "Asia/Kolkata", currency: "INR" },
  { timezone: "Asia/Kathmandu", currency: "NPR" },
  { timezone: "Asia/Dhaka", currency: "BDT" },
  { timezone: "Asia/Yangon", currency: "MMK" },
  { timezone: "Asia/Bangkok", currency: "THB" },
  { timezone: "Asia/Beijing", currency: "CNY" },
  { timezone: "Asia/Shanghai", currency: "CNY" },
  { timezone: "Asia/Tokyo", currency: "JPY" },
  { timezone: "Asia/Seoul", currency: "KRW" },
  { timezone: "Asia/Singapore", currency: "SGD" },
  { timezone: "Asia/Kuala_Lumpur", currency: "MYR" },
  { timezone: "Asia/Jakarta", currency: "IDR" },
  { timezone: "Asia/Taipei", currency: "TWD" },
  { timezone: "Asia/Hong_Kong", currency: "HKD" },
  { timezone: "Asia/Manila", currency: "PHP" },
  { timezone: "Asia/Colombo", currency: "LKR" },

  // Australia & Pacific
  { timezone: "Australia/Perth", currency: "AUD" },
  { timezone: "Australia/Eucla", currency: "AUD" },
  { timezone: "Australia/Darwin", currency: "AUD" },
  { timezone: "Australia/Adelaide", currency: "AUD" },
  { timezone: "Australia/Sydney", currency: "AUD" },
  { timezone: "Australia/Brisbane", currency: "AUD" },
  { timezone: "Australia/Hobart", currency: "AUD" },
  { timezone: "Pacific/Auckland", currency: "NZD" },
  { timezone: "Pacific/Fiji", currency: "FJD" },
  { timezone: "Pacific/Guam", currency: "USD" },
  { timezone: "Pacific/Tongatapu", currency: "TOP" },

  // Africa
  { timezone: "Africa/Cairo", currency: "EGP" },
  { timezone: "Africa/Johannesburg", currency: "ZAR" },
  { timezone: "Africa/Lagos", currency: "NGN" },
  { timezone: "Africa/Nairobi", currency: "KES" },
  { timezone: "Africa/Algiers", currency: "DZD" },
  { timezone: "Africa/Casablanca", currency: "MAD" },
  { timezone: "Africa/Accra", currency: "GHS" },
  { timezone: "Africa/Libreville", currency: "XAF" },
  { timezone: "Africa/Kinshasa", currency: "CDF" },
  { timezone: "Africa/Luanda", currency: "AOA" },
  { timezone: "Africa/Abidjan", currency: "XOF" },
  { timezone: "Africa/Dar_es_Salaam", currency: "TZS" },
  { timezone: "Africa/Kampala", currency: "UGX" },
  { timezone: "Africa/Maputo", currency: "MZN" },
  { timezone: "Africa/Windhoek", currency: "NAD" },

  // Default for unmatched timezones
  { timezone: "default", currency: "Unknown" },
];
