import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";
import { useQuery } from "@apollo/client";

import { PROJECTS_QUERY } from "graphql/query/project";
// import { AUTHENTICATED_ENTRY } from "configs/AppConfig";
import Utils from "utils";

const PublicRoute = () => {
  const user = useSelector((state) => state.user.user);
  const { token } = useSelector((state) => state.auth);
  const { data: projectsData, loading: projectsLoading } = useQuery(
    PROJECTS_QUERY,
    {
      variables: {
        ncpId: Utils.getCompanyId(),
        offset: 0,
        per_page: 100,
      },
      skip: !user || !token,
    }
  );

  // Show loading skeleton if user is logged in and projects data is still loading
  if (token && (!user || projectsLoading)) {
    return <Skeleton active title={false} />;
  }

  // Navigate to the dashboard of the first project if user is logged in
  if (token && projectsData?.projects?.edges?.length > 0) {
    const firstProject = projectsData.projects.edges[0].node;
    const projectId = Utils.decodeId(firstProject.id);
    const companyId = firstProject.ncp_project_id;
    return <Navigate to={`/${companyId}/${projectId}/dashboards/default`} />;
  }

  // Render child components for public routes
  return <Outlet />;
};

export default PublicRoute;
